import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { myApi } from '../../helpers/api';
import { saveAs } from "file-saver";
import moment from "moment";
import useFileService from '../../service/fileService';
import './style.scss';

function InfoCard ({ file, setCards}) {
  const {deleteFile} =useFileService()
  
  const handleDownload = () => {
    saveAs(`${myApi}/${file.path}`, file.originalname);
  };

  const handleDelete = () => {
    deleteFile(file._id,setCards)
  };

  return (
    <Card>
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Button onClick={handleDelete} variant="danger" style={{ borderRadius: "0" }}>
            <strong style={{ color: "white" }}>X</strong>
        </Button>
      </div>
      <Card.Img style={{ objectFit: "cover", height: '200px', width: '100%' }} variant="top" src={`${myApi}/${file.path}`} alt={file.originalname} />
    </div>
    <Card.Body className="d-flex flex-column align-items-center">
      <Card.Title className="text-center">{file.originalname}</Card.Title>
      <Card.Text className="text-center">
        Uploaded - {moment(file.createdAt).format('MM/DD/YYYY')}
      </Card.Text>
      <Button onClick={handleDownload} variant="primary" className="mt-auto">Download</Button>
    </Card.Body>
  </Card>
  );
}

export default InfoCard;
