import React from 'react';
import { Navbar, Nav, Container, Row, Col,Image } from 'react-bootstrap';
import Logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="mt-5">
      <Container className="justify-content-center">
        <Navbar.Brand href="/converter">  <Image
              src={Logo}
              style={{
                border: "1px solid white",
                boxShadow: "black",
                maxHeight: "50px",
                maxWidth: "50px",
                marginRight: "10px",
              }}
              roundedCircle
            /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"  style={{color:"#fff"}}>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Nav.Link href="/converter">Converter</Nav.Link>
            </Col>
            <Col className="text-center">
              <Nav.Link href="/history">History</Nav.Link>
            </Col>
            <Col className="text-center">
              <Nav.Link href="/formats">Formats</Nav.Link>
            </Col>
          </Row>
        </Navbar.Collapse>
        <Row className="mt-3" style={{color:"#fff", maxWidth:"1000px"}}>
          <Col className="text-center" >
            <b>Conversion:</b> Convert your image into various formats, providing maximum flexibility in usage.
          </Col>
          <Col className="text-center">
            <b>Resizing:</b> Crop your image according to your preferences, creating perfect proportions and framing.
          </Col>
          <Col className="text-center">
          <b>Optimization:</b> Minimize the file size of your image while maintaining maximum quality. Our tool allows reducing the file size from 2.5 to 100 times, ensuring the optimal balance between quality and efficiency.
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
