import React, { useState } from "react";
import axios from "axios";
import { Container, Card, Button, Form } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useForm, Controller } from "react-hook-form";
import useFileService from "../../../service/fileService";
import { myApi } from "../../../helpers/api";
import { saveAs } from "file-saver";

import './style.scss';

const UploadForm = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    watch,
  } = useForm();
  const { uploadFile, downloadFile } = useFileService();
  const [changedFile, setChangedFile] = useState(null);


  const selectedAction = watch("action");

  const onSubmit = (data) => {
    uploadFile(data, setChangedFile);
  };


  const handleDownload = () => {
    saveAs(`${myApi}/${changedFile.path}`, changedFile.originalname);
  };
 
  return (
    <Container
      className="d-flex justify-content-center align-items-center text-center"
      style={{ marginTop:"50px"}}
    >
      <Card
        style={{
          maxWidth: "400px",
          width: "100%",
          minHeight: "500px",
          borderTopRightRadius: "100px",
          border: "5px dashed #1C90ED",
        }}
      >
        <Card.Body className="pb-3">
          <Card.Title
            className="mt-2 mb-5"
            style={{ textTransform: "uppercase" }}
          >
            Upload File
          </Card.Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="fileUpload">
              <Form.Label htmlFor="fileInput" className="custom-file-label">
                {/* Choose file */}
              </Form.Label>
              <Form.Control
                id="fileInput"
                type="file"
                className="custom-file-input"
                accept=".jpg, .jpeg, .png, .pdf, .gif"
                 {...register("file", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3"controlId="actionSelect">
              <Form.Label className="mt-3">Select action</Form.Label>
              <Form.Control
                as="select"
                {...register("action", { required: true })}
              >
                <option value="">Select action...</option>
                <option value="convert">Convert</option>
                <option value="resize">Resize</option>
                <option value="optimize">Optimize</option>
                {/* <option value="pdf">Convert to PDF</option>
                <option value="document">Convert to Document</option> */}
              </Form.Control>
              {errors.action && (
                <span className="text-danger">This field is required</span>
              )}
            </Form.Group>
            {selectedAction === "convert" && (
              <>
                <Form.Group controlId="formatSelect">
                  <Form.Label>Select format</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("format", { required: true })}
                  >
                    <option value="jpeg">JPEG</option>
                    <option value="png">PNG</option>
                    {/* <option value="bmp">BMP</option> */}
                    <option value="gif">GIF</option>
                    <option value="webp">WEBP</option>
                  </Form.Control>
                  {errors.format && (
                    <span className="text-danger">This field is required</span>
                  )}
                </Form.Group>
              </>
            )}
            {selectedAction === "resize" && (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group controlId="widthInput" style={{ width: "48%" }}>
                    <Form.Label>Width</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter width"
                      {...register("width", {
                        required: true,
                        min: 100,
                        max: 2000,
                      })}
                    />
                    {errors.width && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="heightInput" style={{ width: "48%" }}>
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter height"
                      {...register("height", {
                        required: true,
                        min: 100,
                        max: 2000,
                      })}
                    />
                    {errors.height && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </Form.Group>
                </div>
              </>
            )}
            {selectedAction === "optimize" && (
              <>
                <Controller
                  name="quality"
                  control={control}
                  defaultValue={50} // Set default value
                  render={({ field }) => (
                    <>
                      <Form.Label>Quality {field.value}</Form.Label>
                      <Form.Control
                        {...field}
                        type="range"
                        min={1}
                        max={100}
                        custom 
                      />
                 
                    </>
                  )}
                />
              </>
            )}
            <Button
              type="submit"
              variant="primary"
              className="position-relative"
              style={{ bottom: "-30px" }}
            >
              Upload
            </Button>
            <Button
              style={{
                position: "absolute",
                bottom: "40px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#1ab047",
              }}
              onClick={handleDownload}
              className="mt-3"
              disabled={!changedFile ? true : false}
            >
              Download <FaDownload />
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UploadForm;
